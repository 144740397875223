// 服务中心
<template>
  <div class="serve " v-if="$isPc">
    <router-view />
  </div>
  <el-container v-else>
    <el-aside width="100px" class="personal-navs mt-20 pb-50">
      <el-menu :default-active="activeIndex" router class="el-menu-vertical-demo ptb-32" background-color="#FFFFFF"
        text-color="#8490AD" active-text-color="#2974EF">
        <el-menu-item v-for="(nav,index) in navs" :key="index" :index="nav.route_url" :route="nav.route_url">
          <span> {{nav.name}}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </el-main>
  </el-container>

</template>

<script>
export default {
  name: 'Serve',
  components: {},
  data () {
    return {
      activeIndex: this.$route.path,
      navs: [
        { name: '金融产品', route_url: '/serve/product', icon: 'el-icon-bell' },
        { name: '政策服务', route_url: '/policy', icon: 'el-icon-tickets' },
        { name: '新闻动态', route_url: '/news', icon: 'el-icon-tickets' },
        { name: '活动专场', route_url: '/activity', icon: 'el-icon-date' },
        { name: '运营联盟', route_url: '/union', icon: 'el-icon-date' },
        { name: '合作机构', route_url: '/serve/partners', icon: 'el-icon-download' },
      ]
    };
  },
  watch: {
    $route (to, from) {
      this.activeIndex = to.path;
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .load-more-wrapper {
  padding: 10px;
}
</style>